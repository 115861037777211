import { ethers } from "ethers";
import keccak256 from "keccak256";
import MerkleTree from "merkletreejs";
import { Buffer } from 'buffer';
import { 
    Token, 
    WETH, 
    Fetcher, 
    Route, 
    Trade, 
    TokenAmount, 
    TradeType, 
    Percent
} from "@uniswap/sdk";
const UNISWAP = require("@uniswap/sdk")

const OxOTOKEN_ADDRESS = "0x5a3e6A77ba2f983eC0d371ea3B475F8Bc0811AD5";

// @ts-ignore
window.Buffer = Buffer;

class BigDecimal {
    constructor(value) {
        let [ints, decis] = String(value).split(".").concat("");
        decis = decis.padEnd(BigDecimal.decimals, "0");
        this.bigint = BigInt(ints + decis);
    }
    static fromBigInt(bigint) {
        return Object.assign(Object.create(BigDecimal.prototype), { bigint });
    }
    divide(divisor) { // You would need to provide methods for other operations
        return BigDecimal.fromBigInt(this.bigint * BigInt("1" + "0".repeat(BigDecimal.decimals)) / divisor.bigint);
    }
    toString() {
        const s = this.bigint.toString().padStart(BigDecimal.decimals+1, "0");
        return s.slice(0, -BigDecimal.decimals) + "." + s.slice(-BigDecimal.decimals)
                .replace(/\.?0+$/, "");
    }
}
BigDecimal.decimals = 18;

async function getSwapData(amountIn, slippage, provider){
    const _WETH = WETH[1];
    const tokenOut = {
        decimals: 9,
        address: OxOTOKEN_ADDRESS
    }
    const TokenOut = new Token(
        UNISWAP.ChainId.MAINNET,
        tokenOut.address,
        tokenOut.decimals,
    );

    const pair = await Fetcher.fetchPairData(_WETH, TokenOut, provider);
    const route = new Route([pair], _WETH);

    const trade = new Trade(
        route,
        new TokenAmount(_WETH, amountIn),
        TradeType.EXACT_INPUT
    );

    const slippageTolerance = new Percent(slippage, "10000"); // 50 bips, or 0.50% - Slippage tolerance
    const amountOutMin = trade.minimumAmountOut(slippageTolerance).raw;
    const amountOutMinBigNum = ethers.BigNumber.from(amountOutMin.toString());
    
    let amountOutDecimal = new BigDecimal(amountOutMinBigNum);
    let d = new BigDecimal(Math.pow(10, tokenOut.decimals));
    amountOutDecimal = parseFloat(parseFloat(amountOutDecimal.divide(d).toString()).toFixed(3));
    
    return {
        decimals: tokenOut.decimals,
        amountMinOut: amountOutMinBigNum,
        amountMinOutHex: amountOutMinBigNum.toHexString(),
        amountOut: amountOutMinBigNum,
        amountOutDecimal: amountOutDecimal,
    }
}

/**
 * Generate Merkle Tree leaf from address and value
 * @param {string} address of airdrop claimee
 * @param {string} value of airdrop tokens to claimee
 * @returns {Buffer} Merkle Tree node
 */
function generateLeaf(address, value) {
    return Buffer.from(
      // Hash in appropriate Merkle format
      ethers.utils
        .solidityKeccak256(["address", "uint256"], [address, value.toString()])
        .slice(2),
      "hex"
    );
}

function generateTree(leafs){
    const merkleTree = new MerkleTree(
        leafs,
        // Hashing function
        keccak256,
        { sortPairs: true }
    );

    return merkleTree;
}
  
export { 
    getSwapData, 
    BigDecimal,
    generateLeaf,
    generateTree
} 