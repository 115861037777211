import React, { useEffect, useState } from 'react';
import { BigNumber, ethers } from "ethers";

function CalcInfo({ OXOContract, holderAddress }){

    const [tokenPriceInWETH, setTokenPriceInWETH] = useState(0);
    const [adjustedTokenSupply, setAdjustedTokenSupply] = useState(0);
    const [myRewardsInWETH, setMyRewardsInWETH] = useState(0);
    const [theirRewardsInWETH, setTheirRewardsInWETH] = useState(0);
    const [tokenAmountForCustom, setTokenAmountForCustom] = useState(0);
    const [rewardsInWETH, setRewardsInWETH] = useState(0);
    const [tokenAmount, setTokenAmount] = useState(0);
    const [ethReplenishedForCustom, setEthReplenishedForCustom] = useState(0);
    const [frequencyInDays, setFrequencyInDays] = useState(0);
    const [calculateCustomVolume, setCalculateCustomVolume] = useState(0);
    const [theirAddress, setTheirAddress] = useState("");

    useEffect(() => {
        const getCalcInfo = async () => {
            if(OXOContract == null) return;

            setTokenPriceInWETH(
                ethers.utils.formatEther(
                    await OXOContract.calculateTokenPriceInWETH()
                )
            );


            setAdjustedTokenSupply(
                parseInt(
                    (await OXOContract.calculateAdjustedTokenSupply())._hex
                )
            );

            setMyRewardsInWETH(
                ethers.utils.formatEther(
                    await OXOContract.calculateHolderRewardsInWETH(holderAddress)
                )
            )
        };

        getCalcInfo();
    }, [OXOContract]);

    useEffect(() => {
        const getMyRewards = async () => {
            if(OXOContract == null) return;

            let val = parseInt(tokenAmount);
            if (val > 0) {
                setRewardsInWETH(
                    ethers.utils.formatEther(
                        await OXOContract.calculateRewardsInWETH(val)
                    )
                );
            }
        };

        getMyRewards();
    }, [tokenAmount]);

    useEffect(() => {
        const init = async () => {
            if (OXOContract == null) return;
            setTheirRewardsInWETH("0");
    
            // Check if theirAddress is a non-empty string
            if (typeof theirAddress === 'string' && theirAddress.trim() !== "") {
                try {
                    const rewards = await OXOContract.calculateHolderRewardsInWETH(theirAddress);
                    setTheirRewardsInWETH(ethers.utils.formatEther(rewards));
                } catch (error) {
                    // Handle error, e.g., address/ENS name is invalid
                    console.error("Error fetching rewards:", error);
                }
            }
        };
    
        init();
    }, [theirAddress]);
      

    useEffect(() => {
        const calculateCustomVolume = async () => {
            if(OXOContract == null) return;

            let val = BigNumber.from(tokenAmountForCustom || 0);
            let daysVal = BigNumber.from(frequencyInDays || 0);

            if (val > 0 && daysVal > 0) {
                if(ethReplenishedForCustom == null || ethReplenishedForCustom == 0 || ethReplenishedForCustom == "") return;
                let ethVal = ethers.utils.parseEther(ethReplenishedForCustom);
                let { 
                    yourEthRewardsInWei,
                    r,
                    n,
                    APR, 
                    APY
                } = await OXOContract.calculateCustomVolume(val, ethVal, daysVal)
                setCalculateCustomVolume(ethers.utils.formatEther(yourEthRewardsInWei))
            }
        }

        calculateCustomVolume();
    }, [tokenAmountForCustom, ethReplenishedForCustom, frequencyInDays]);

    return (
        <div className='w-full p-2 flex flex-col justify-center items-center break-words'>
            <p className="text-center mb-2">0x0 token price in ETH: <span className="text-[#36d2cd]">{parseFloat(tokenPriceInWETH).toFixed(8)} ETH</span></p>

            <p className="text-center mb-3">Adjusted token supply: <span className="text-[#36d2cd]">{adjustedTokenSupply.toLocaleString()} 0x0</span></p><br/><br/>

            <p className="text-left mb-6">
                <span>➜ How much ETH will I receive if I hold</span>
                <input
                    type="number"
                    placeholder="token amount with no decimals"
                    className="text-black outline-none text-sm px-2 rounded-md mx-2"
                    onChange={(e) => {
                        setTokenAmount(e.target.value);
                    }}
                />
                <span>amount of tokens? Answer: </span>
                <span className="text-[#36d2cd]">
                    {parseFloat(rewardsInWETH).toFixed(3)} ETH
                </span>
            </p>

            <p className="text-left mb-6">
                <span>➜ How much ETH will I receive based on my current holding at address
                    <input
                    type="text"
                    placeholder="input holder address"
                    className="text-black outline-none text-sm px-2 rounded-md mx-2"
                    defaultValue={holderAddress}
                    disabled={true}
                />? Answer: </span>
                <span className="text-[#36d2cd]">
                    {parseFloat(myRewardsInWETH).toFixed(3)} ETH
                </span>
            </p>

            <p className="text-left mb-6">
                <span>➜ How much ETH will an address</span>
                <input
                    type="text"
                    placeholder="input holder address"
                    className="text-black outline-none text-sm px-2 rounded-md mx-2"
                    onChange={(e) => {
                        setTheirAddress(e.target.value);
                    }}
                />
                <span>receive as their rewards? Answer: </span>
                <span className="text-[#36d2cd]">
                    {parseFloat(theirRewardsInWETH).toFixed(3)} ETH
                </span>
            </p>


            <p className="text-left mb-6">
                <span>➜ How much ETH will I receive based on</span>
                <input
                    type="number"
                    placeholder="token amount with no decimals"
                    className="text-black outline-none text-sm px-2 rounded-md mx-2"
                    onChange={(e) => {
                        let v = e.target.value;
                        setTokenAmountForCustom(e.target.value);
                    }}
                />
                <span>tokens, if there was a total of</span>
                <input
                    type="number"
                    placeholder="ETH"
                    className="text-black outline-none text-sm px-2 rounded-md mx-2"
                    onChange={(e) => {
                        setEthReplenishedForCustom(e.target.value);
                    }}
                />
                <span>ETH volume in rewards over a period of</span>
                <input
                    type="number"
                    placeholder="days"
                    className="text-black outline-none text-sm px-2 rounded-md mx-2"
                    onChange={(e) => {
                        setFrequencyInDays(e.target.value);
                    }}
                />
                <span>days as frequency? Answer:</span>{" "}
                <span className="text-[#36d2cd]">
                    {parseFloat(calculateCustomVolume).toFixed(3)} ETH
                </span>
            </p>          
        </div>
    )
}

export default CalcInfo;