import { ConnectButton } from '@rainbow-me/rainbowkit';

function Header() {

    return (
        <header className='w-full flex justify-between items-center py-8 px-4 md:py-16 md:px-12'>
            <div>
                <img src="/0x0/logo.svg" alt="0x0 Logo" width={120} height={120} />
            </div>
            <div>
                <ConnectButton 
                    showBalance={true} 
                    accountStatus="address"
                />
            </div>
        </header>
    )
}

export default Header;