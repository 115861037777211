import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useSwitchNetwork } from 'wagmi';

function NotConnected() {

    return (
        <>
            <h1 className="text-center text-2xl font-bold">You are not authenticated</h1>
            <p className="text-center text-lg my-4">
                Please connect with your wallet to check your reward
            </p>
            <ConnectButton 
                showBalance={true} 
                accountStatus="address"
                chainStatus="none"
            />
        </>
    );
}

function WrongNetwork(){
    const { chains, switchNetwork } = useSwitchNetwork()

    return (
        <>
            <h1 className="text-center text-2xl font-bold">
                Wrong Network
            </h1>
            <p className="text-center text-lg my-4">
                Please switch to {chains[0].name} to check your reward
            </p>
            <button
                className="bg-[#36d2cd] text-white font-bold py-2 px-4 rounded-xl"
                onClick={() => switchNetwork(chains[0].id)}
            >
                Switch Network
            </button>
        </>
    )
}

export { NotConnected, WrongNetwork };