import { ethers } from "ethers";
import Contract from './data/contract.json';
import ABI from './data/abi.json';

const rpcURL = `https://eth-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_KEY}`;
const provider = new ethers.providers.JsonRpcProvider(rpcURL);
const contract = new ethers.Contract(Contract.address, ABI, provider);
const ETHER_SCAN_URL = `https://etherscan.io/`;

function getContract(provider=null) {
    if (provider) {
        return new ethers.Contract(Contract.address, ABI, provider);
    }
    return contract;
}
export default contract;
export { getContract, ETHER_SCAN_URL };