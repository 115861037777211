import React, { useState, useEffect, useRef } from "react";
import { useAccount, useNetwork, useWalletClient } from 'wagmi';
import { ethers, BigNumber, providers } from 'ethers';
import { getSwapData, BigDecimal, generateTree, generateLeaf } from '../helpers';
import { getContract, ETHER_SCAN_URL } from "../contract";
import Rewards from '../data/rewards.json';
import { toast } from 'react-toastify';
import { NotConnected, WrongNetwork } from "./connect";
import CalcInfo from "./calcInfo";

export function walletClientToSigner(walletClient) {
    const { account, chain, transport } = walletClient
    const network = {
      chainId: chain.id,
      name: chain.name,
      ensAddress: chain.contracts?.ensRegistry?.address,
    }
    const provider = new providers.Web3Provider(transport, network)
    const signer = provider.getSigner(account.address)
    return signer
}

export function useEthersSigner({ chainId }={}) {
    const { data: walletClient } = useWalletClient({ chainId })
    return React.useMemo(
      () => (walletClient ? walletClientToSigner(walletClient) : undefined),
      [walletClient],
    )
}

function toChecksumAddress(address) {
    try{
        return ethers.utils.getAddress(address);
    }catch(e){
        return address;
    }
}

const leaves = Object.entries(Rewards.airdrop).map(([address, index]) => {
    address = toChecksumAddress(address);
    return generateLeaf(address, index)
});


const OXOMerkleTree = generateTree(leaves);


function Claim() {
    const airdrops = Rewards["airdrop"];

    const sliderRef = useRef(null);
    const rewardRef = useRef(null);

    const { address, isConnected } = useAccount();
    const [reward, setReward] = useState('0');

    const [tokenReward, setTokenReward] = useState('0');
    const [tokenRewardWEI, setTokenRewardWEI] = useState('0');
    const [userClaimedETH, setUserClaimedETH] = useState('0');
    const [userInitialReward, setUserInitialReward] = useState('0');
    const [userMaxReward, setUserMaxReward] = useState('0');
    const [totalETHDistributed, setTotalETHDistributed] = useState('0');
    const [totalETHClaimed, setTotalETHClaimed] = useState('0');

    const [APR, setAPR] = useState('0');
    const [APY, setAPY] = useState('0');

    // Convert Big Numbers to percentages
    const convertToPercentage = (value) => {
        const percentage = ethers.utils.formatUnits(value, 16);
        return parseFloat(percentage).toFixed(3);
    };

    const [OXOContract, setContract] = useState(null);
    const { chain, chains } = useNetwork();
    const signer = useEthersSigner({ chainId: chain?.id })

    useEffect(() => {
        // Load contract when connected
        if(address !== undefined && chain !== undefined && signer !== undefined){

            if(chain.id === chains[0].id){
                setContract(getContract(signer))
            }
        }
        
    }, [isConnected, chain, signer]);

    useEffect(() => {
        // Load ETH claimed and ETH distributed
        const init = async () => {
            if(OXOContract === null){
                return;
            }

            let totalClaimedEth = await OXOContract.totalClaimedEth();
            let totalEthForRewards = await OXOContract.totalEthForRewards();

            setTotalETHClaimed(ethers.utils.formatEther(totalClaimedEth.toString()));
            setTotalETHDistributed(ethers.utils.formatEther(totalEthForRewards.toString()));

                        
            const { APR, APY } = await OXOContract.calculateAPYAndAPR();

            setAPR(convertToPercentage(APR));
            setAPY(convertToPercentage(APY));

            if(address !== undefined){
                let userClaimedETH = await OXOContract.amountClaimed(toChecksumAddress(address));
                let userInitialReward = BigNumber.from(getReward(toChecksumAddress(address)).toString());

                setUserClaimedETH(userClaimedETH.toString());
                setUserInitialReward(userInitialReward.toString());
                setUserMaxReward(userInitialReward.sub(userClaimedETH).toString());
            }
        }
        init();
    }, [OXOContract]);

    useEffect(() => {
        // Get swap data every time reward changes
        const init = async () => {
            if (OXOContract === null) {
                return;
            }
            let rewardWei = ethers.utils.parseEther(reward);
            if(rewardWei.isZero()){
                setTokenReward('0');
                return;
            }
            try{
                let data = await getSwapData(
                    rewardWei, 
                    600, // slippage for 6% (5% tax + 1% slippage) 
                    OXOContract.provider);
                let amountOut = new BigDecimal(data.amountOut);
                let amountOutParsed = amountOut.divide(new BigDecimal(10 ** 9)).toString();
                setTokenRewardWEI(data.amountOut.toHexString());
                setTokenReward(parseFloat(amountOutParsed).toFixed(0));
            }catch(e){
                setTokenReward('0');
                console.log(e);
            }
            
        }
        init();
    }, [reward]);

    const getReward = (address) => {
        address = toChecksumAddress(address);
        let v = airdrops[address];
        
        if (v === undefined) {
            v = 0;
        }
        return v;
    }

    function setCalculatedReward(percent) {
        percent = parseInt(percent);

        let v = userMaxReward;
        let percentAsDecimal = percent / 100;
        let pa = parseInt(v * percentAsDecimal);

        if(pa > userMaxReward){
            pa = userMaxReward;
        }
        rewardRef.current.value = ethers.utils.formatEther(pa.toString());
        setReward(ethers.utils.formatEther(pa.toString()))
    }

    function checkAndSetReward(val){
        let ve = ethers.utils.formatEther(userMaxReward.toString());
        let percent = (val / ve) * 100;

        if(percent > 100){
            sliderRef.current.value = 100;
        }{
            sliderRef.current.value = percent;
        }
        setReward(val.toString());
    }

    function doTxHashToast(tx){
        let txRender = (msg, txHash) => {
            return <>
                <p>{msg}</p>
                <strong className='font-bold text-sm mt-3'>View on etherscan: </strong>
                <a href={ETHER_SCAN_URL + 'tx/' + txHash} target='blank' className='break-all text-xs underline line-clamp-1'>
                    {ETHER_SCAN_URL}tx/{txHash}
                </a>
              </>
        };
        
        toast.promise(
            tx.wait(),
            {
              pending: {
                render: txRender('Transaction pending...', tx.hash),
              },
              success: {
                render: txRender('Transaction successful', tx.hash),
              },
              error: {
                render: txRender('Transaction failed', tx.hash),
              },
        });
    }

    function getClaimETHParams(){
        let amount = userInitialReward.toString();

        let leaf = generateLeaf(address, amount);
        let proof = OXOMerkleTree.getHexProof(leaf);
        let claimAmount = ethers.utils.parseEther(rewardRef.current.value);
        if(claimAmount.gt(userMaxReward)){
            claimAmount = userMaxReward;
        }

        return {
            amount,
            proof,
            claimAmount
        }
    }

    async function claimETH() {
        
        let { amount, proof, claimAmount } = getClaimETHParams();
        
        let gasEstimate = await OXOContract.estimateGas.claimEth(
            amount, 
            proof, 
            claimAmount, 
            { "from": toChecksumAddress(address) }
        );
        let tx = await OXOContract.claimEth(amount, proof, claimAmount, { gasLimit: gasEstimate });
        doTxHashToast(tx);
    }

    async function claimToken() {
        let { amount, proof, claimAmount } = getClaimETHParams();
        let minAmountOut = tokenRewardWEI;

        let gasEstimate = await OXOContract.estimateGas.claimTokens(
            amount, 
            proof, 
            claimAmount, 
            minAmountOut, 
            { "from": toChecksumAddress(address) }
        );
        let tx = await OXOContract.claimTokens(amount, proof, claimAmount, minAmountOut, { gasLimit: gasEstimate });
        doTxHashToast(tx);
    }


    return (
        <div className="text-white flex justify-center items-center py-10 px-3">
            <div className="flex flex-col justify-center items-center border border-white rounded-md px-5 py-10 w-full max-w-[600px]">
                {!isConnected? (
                    <NotConnected />
                ) : (
                    chain.id !== chains[0].id ? (
                        <WrongNetwork />
                    ):(
                        // getReward(address) === 0 ? (
                        //     <h1 className="text-center text-2xl font-bold">Your address does not qualify for any rewards yet, please await the next snapshot!</h1>
                        // ):(
                            <>
                                <h1 className="text-center text-2xl font-bold my-5">Your address qualifies for...</h1>
                                <h1 className="text-center text-xl font-bold text-[#36d2cd]">Claim your rewards</h1><br/>
                                <p>Your total reward: <span className="text-[#36d2cd]">{parseFloat(ethers.utils.formatEther(userInitialReward)).toFixed(3)} ETH</span></p><br/>
                                <p>Your claimed reward: <span className="text-[#36d2cd]">{parseFloat(ethers.utils.formatEther(userClaimedETH)).toFixed(3)} ETH</span></p><br/>
                                <p>Your unclaimed reward: <span className="text-[#36d2cd]">{parseFloat(ethers.utils.formatEther(userMaxReward)).toFixed(3)} ETH</span></p>

                                <input 
                                    type="range" 
                                    min="0" 
                                    max="100" 
                                    className="my-10" 
                                    step="1" 
                                    defaultValue={0}
                                    ref={sliderRef}
                                    onChange={(e) => {
                                            setCalculatedReward(e.target.value);
                                        }
                                    }
                                />

                                <div className="flex w-full border border-white rounded-2xl">
                                    <input 
                                        type="number"
                                        min={0}
                                        className="w-full bg-transparent border-r border-r-2 outline-none text-white text-center text-lg py-2"
                                        defaultValue={`${reward}`}
                                        ref={rewardRef}
                                        onChange={(e) => {
                                                checkAndSetReward(e.target.value.trim() != ""? parseFloat(e.target.value.trim()) : 0);
                                            }
                                        }
                                    />
                                    <span className="py-2 px-5">ETH</span>
                                </div>

                                <button
                                    className="bg-[#36d2cd] text-white text-lg font-bold py-2 px-4 rounded-2xl  my-5 w-full" 
                                    onClick={claimETH}
                                >
                                    Claim
                                </button>

                                <h1 className="text-center text-xl font-bold text-[#36d2cd]">Swap for 0x0</h1><br/>
                                <p className="text-center">You get <span className="text-[#36d2cd]">{tokenReward.toLocaleString()} 0x0</span></p>
                                <button
                                    className="bg-[#36d2cd] text-white text-lg font-bold py-2 px-4 rounded-2xl  my-5 w-full" 
                                    onClick={claimToken}
                                >
                                    Compound
                                </button>

                                <h1 className="text-center text-2xl font-bold my-5">Calculator</h1>
                                <h1 className="text-center text-xl font-bold text-[#36d2cd]">Estimates are averaged based on past volume</h1><br/>

                                <p className="text-center">Total ETH distributed: <span className="text-[#36d2cd]">{parseFloat(totalETHDistributed).toFixed(3)}</span></p><br/>
                                <p className="text-center">Total ETH claimed: <span className="text-[#36d2cd]">{parseFloat(totalETHClaimed).toFixed(3)}</span></p><br/>

                                <p className="text-center">Estimated APR: <span className="text-[#36d2cd]">{APR}%</span></p><br/>
                                <p className="text-center">Estimated APY: <span className="text-[#36d2cd]">{APY}%</span></p>

                                <div className="my-5">
                                    <CalcInfo 
                                        OXOContract={OXOContract}
                                        holderAddress={toChecksumAddress(address)}
                                    />
                                </div>
                            </>
                        // )
                    )
                )}
            </div>
        </div>
    );
}

export default Claim;