import React from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../components/header';
import Claim from "../components/claim";


function Home() {
    return (
      <div className="h-[120vh]">
        <ToastContainer />
        <Header />
        <Claim />
      </div>
    );
}

export { Home };